







import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Spinner'
})
export default class Spinner extends Vue {
  private messageNumber = 0;
  private timer: any;

  private messages: string[] = ['', '', ''];

  get waitingMessage() {
    return this.messages[this.messageNumber];
  }

  /**
   * On mount a timer is starter, once the timer is triggered it starts another timer with increased delay.
   * When the 3rd timer is triggered, it will not start a new timer, but instead redirect to an error page
   */
  mounted() {
    this.timer = setTimeout(() => {
      this.messageNumber++;
      this.timer = setTimeout(() => {
        this.messageNumber++;
        this.timer = setTimeout(() => {
          this.$emit('timeout');
        }, 12000);
      }, 8000);
    }, 6000);
  }

  beforeDestroy() {
    clearTimeout(this.timer);
  }
}
