









































import { programService } from "../service/program.service";
import { Programpunkt } from "../../../server/src/models/programpunkt"
import { Component, Vue, Watch } from "vue-property-decorator";
import Spinner from '@/components/Spinner.vue';

@Component({
  components: {
    Spinner
  }
})
export default class Program extends Vue {
  private rolle = this.$store.state.rolle;
  private loading = false;

  private program: Programpunkt[] = [];
  private error = "";
  datoOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  @Watch('$route')
  private async hentProgram() {
    this.loading = true;
    try {
        if (this.$route.name == 'Historisk program') {
          this.program = await programService.getProgram(true);
        } else {
          this.program = await programService.getProgram(false);
        }
        this.program.sort((a,b) => {
            if (a.tidspunkt.dato === b.tidspunkt.dato) {
              return a.tidspunkt.fra < b.tidspunkt.fra ? -1 : 1
            } else {
              return a.tidspunkt.dato < b.tidspunkt.dato ? -1 : 1
            }
        })
      } catch(err) {
          this.error = err
      } finally {
        this.loading = false;
      }
  }

  public async mounted() {
    this.hentProgram();
  }
}
