import { Programpunkt } from '../../../server/src/models/programpunkt'
import { httpService } from '@/service/http.service';

const url = '/api/program'

export class ProgramService {
    public async getProgram(alle: boolean) {
        try {
            let res
            if (alle) {
                res = await httpService.get(url + "?searchMode=alle");
            } else {
                res = await httpService.get(url);
            }
    
            return res.data.map((entry: Programpunkt) => ({
                ...entry,
            }))
        } catch (err) {
            console.log(err)
            return err;
        }
    }
}

export const programService = new ProgramService();
